import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";
import Main from "../pages/main/Main.vue";
import Auth from "../pages/auth/Auth.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/",
    name: "Main",
    component: Main,
    meta: { requiresAuth: true },
    children: [
      {
        path: "home",
        name: "Home",
        meta: {
          title: "Início",
          requiresAuth: true,
        },
        component: () => import("@/pages/main/views/home/Home.vue"),
      },
      {
        path: "accounts",
        name: "Accounts",
        redirect: { name: "Overview" },
        component: () => import("@/pages/main/views/accounts/Accounts.vue"),
        children: [
          {
            path: "overview",
            name: "Overview",
            meta: {
              title: "Overview",
              requiresPermission: "accounts",
              requiresAuth: true,
            },
            component: () =>
              import(
                "@/pages/main/views/accounts/children/overview/Overview.vue"
              ),
          },
          {
            path: "create-account",
            name: "CreateAccount",
            meta: {
              title: "Conectar Conta API",
              requiresPermission: "accounts",
              requiresAuth: true,
            },
            component: () =>
              import(
                "@/pages/main/views/accounts/children/createAccount/CreateAccount.vue"
              ),
          },
          {
            path: "account-details/:id",
            name: "AccountDetails",

            redirect: { name: "AccountSummary" },
            component: () =>
              import(
                "@/pages/main/views/accounts/children/accountDetails/AccountDetails.vue"
              ),
            children: [
              {
                path: "summary",
                name: "AccountSummary",
                meta: {
                  title: "Detalhes da conta",
                  requiresPermission: "accounts",
                  requiresAuth: true,
                },
                component: () =>
                  import(
                    "@/pages/main/views/accounts/children/accountDetails/children/summary/Summary.vue"
                  ),
              },
              {
                path: "edit",
                name: "EditAccount",
                meta: {
                  title: "Editar Conta API",
                  requiresPermission: "accounts",
                  requiresAuth: true,
                },
                component: () =>
                  import(
                    "@/pages/main/views/accounts/children/accountDetails/children/editAccount/EditAccount.vue"
                  ),
              },
              {
                path: "history",
                name: "AccountHistory",
                redirect: { name: "TradesHistory" },
                component: () =>
                  import(
                    "@/pages/main/views/accounts/children/accountDetails/children/history/History.vue"
                  ),
                children: [
                  {
                    path: "trades",
                    name: "TradesHistory",
                    meta: {
                      title: "Histórico da conta",
                      requiresPermission: "accounts",
                      requiresAuth: true,
                    },
                    component: () =>
                      import(
                        "@/pages/main/views/accounts/children/accountDetails/children/history/children/trades/Trades.vue"
                      ),
                  },
                  {
                    path: "positions",
                    name: "PositionsHistory",
                    meta: {
                      title: "Histórico da conta",
                      requiresPermission: "accounts",
                      requiresAuth: true,
                    },
                    component: () =>
                      import(
                        "@/pages/main/views/accounts/children/accountDetails/children/history/children/positions/Positions.vue"
                      ),
                  },
                  {
                    path: "orders",
                    name: "OrdersHistory",
                    meta: {
                      title: "Histórico da conta",
                      requiresPermission: "accounts",
                      requiresAuth: true,
                    },
                    component: () =>
                      import(
                        "@/pages/main/views/accounts/children/accountDetails/children/history/children/orders/Orders.vue"
                      ),
                  },
                ],
              },
              {
                path: "open-orders",
                name: "AccountOpenOrders",
                meta: {
                  title: "Ordens abertas",
                  requiresPermission: "accounts",
                  requiresAuth: true,
                },
                component: () =>
                  import(
                    "@/pages/main/views/accounts/children/accountDetails/children/openOrders/OpenOrders.vue"
                  ),
              },
              {
                path: "open-positions",
                name: "AccountOpenPositions",
                meta: {
                  title: "Posições abertas",
                  requiresPermission: "accounts",
                  requiresAuth: true,
                },
                component: () =>
                  import(
                    "@/pages/main/views/accounts/children/accountDetails/children/openPositions/OpenPositions.vue"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "people",
        name: "People",
        meta: {
          title: "Pessoas",
          requiresAuth: true,
          requiresPermission: "people",
        },
        component: () => import("@/pages/main/views/people/People.vue"),
      },
      {
        path: "algorithms",
        name: "Algorithms",
        component: () => import("@/pages/main/views/algorithms/Algorithms.vue"),
        children: [
          {
            path: "",
            name: "Algorithms",
            meta: {
              title: "Algoritmos",
              requiresAuth: true,
              requiresPermission: "algorithms",
            },
            component: () =>
              import(
                "@/pages/main/views/algorithms/children/marketplace/Marketplace.vue"
              ),
          },
        ],
      },
      {
        path: "manage-algorithms",
        name: "ManageAlgorithms",
        redirect: { name: "ListAlgorithms" },
        component: () =>
          import("@/pages/main/views/manageAlgorithms/ManageAlgorithms.vue"),
        children: [
          {
            path: "list-algorithms",
            name: "ListAlgorithms",
            meta: {
              title: "Algoritmos",
              requiresAuth: true,
              requiresPermission: "algorithms",
            },
            component: () =>
              import(
                "@/pages/main/views/manageAlgorithms/children/listAlgorithms/ListAlgorithms.vue"
              ),
          },
          {
            path: "create-algorithm",
            name: "CreateAlgorithm",
            meta: {
              title: "Criar algoritmos",
              requiresAuth: true,
              requiresPermission: "algorithms.create",
            },
            component: () =>
              import(
                "@/pages/main/views/manageAlgorithms/children/createAlgorithm/CreateAlgorithm.vue"
              ),
          },
          {
            path: "edit-algorithm/:id",
            name: "EditAlgorithm",
            meta: {
              title: "Editar algoritmos",
              requiresAuth: true,
              requiresPermission: "algorithms.edit",
            },
            component: () =>
              import(
                "@/pages/main/views/manageAlgorithms/children/editAlgorithm/EditAlgorithm.vue"
              ),
          },
        ],
      },
      {
        path: "transactions",
        name: "Transactions",
        meta: {
          title: "Transações",
          requiresPermission: "swaps",
          requiresAuth: true,
        },
        component: () =>
          import("@/pages/main/views/transactions/Transactions.vue"),
      },
      {
        path: "exchanges",
        name: "Exchanges",
        meta: {
          title: "Exchanges",
          requiresPermission: "exchanges",
          requiresAuth: true,
        },
        component: () => import("@/pages/main/views/exchanges/Exchanges.vue"),
      },
      {
        path: "pairs",
        name: "Pairs",
        meta: {
          title: "Pares",
          requiresPermission: "exchanges",
          requiresAuth: true,
        },
        component: () => import("@/pages/main/views/pairs/Pairs.vue"),
      },
      {
        path: "currencies",
        name: "Currencies",
        meta: {
          title: "Criptomoedas",
          requiresPermission: "coins",
          requiresAuth: true,
        },
        component: () => import("@/pages/main/views/currencies/Currencies.vue"),
      },
      {
        path: "group-permissions",
        name: "GroupPermissions",
        meta: {
          title: "GroupPermissions",
          requiresPermission: "permission-groups",
          requiresAuth: true,
        },
        component: () =>
          import("@/pages/main/views/groupPermissions/GroupPermissions.vue"),
      },
      {
        path: "logs",
        name: "Logs",
        meta: {
          title: "Logs de sistema",
          requiresPermission: "logs.system",
          requiresAuth: true,
        },
        component: () => import("@/pages/main/views/logs/Logs.vue"),
      },
      {
        path: "requests",
        name: "Requests",
        meta: {
          title: "Logs de Requisições",
          requiresPermission: "logs.requests",
          requiresAuth: true,
        },
        component: () => import("@/pages/main/views/requests/Requests.vue"),
      },
      {
        path: "signals",
        name: "Signals",
        meta: {
          title: "Sinais",
          requiresPermission: "signals",
          requiresAuth: true,
        },
        component: () => import("@/pages/main/views/signals/Signals.vue"),
      },
      {
        path: "access-denied",
        name: "AccessDenied",
        meta: { title: "Acesso Negado", requiresAuth: true },
        component: () =>
          import("@/pages/main/views/accessDenied/AccessDenied.vue"),
      },
    ],
  },
  {
    path: "/auth",
    name: "Auth",
    meta: { preventAuth: true },
    component: Auth,
    children: [
      {
        path: "sign-in",
        name: "SignIn",
        alias: "",
        meta: { title: "Autenticação", preventAuth: true },
        component: () => import("@/pages/auth/views/signIn/SignIn.vue"),
      },
      /* {
        path: "sign-up",
        name: "SignUp",
        meta: { title: "Registro", preventAuth: true },
        component: () => import("@/pages/auth/views/signUp/SignUp.vue"),
      }, */
    ],
  },
  {
    path: "*",
    redirect: "/home",
  },
];

const router = new VueRouter({
  //mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
